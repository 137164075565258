import {
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Components/Loader";
import { storage } from "../config/firebase";
import {
  CREATE_PROJECT,
  FACE_DETECTOR,
  GET_USERS,
  TRANSCRIBE,
  UPLOAD_AUDIO_DRIVE,
  UPLOAD_AUDIO_DROPBOX,
  UPLOAD_VIDEO_DRIVE,
  UPLOAD_VIDEO_DROPBOX,
} from "../MProjectConst";
import { languagesCode } from "../Data/LanguagesCode.js";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import DeleteIcon from "@mui/icons-material/Delete";
import { getLanguageName, getTaskName } from "../utils/generalFunctions.js";
import { ToastContainer, toast } from "react-toastify";
import SRTSubtitleParser from "../Components/ProjectCreationComponents/SRTSubtitleParser.js";
import classes from "../Assets/Styles/Global.module.css";
import GlobalButton from "../Components/Buttons/GlobalButton.js";
import TaskButton from "../Components/Buttons/TaskButton.js";

const ProjectCreationScreen = () => {
  const navigate = useNavigate();

  const [videoURL, setVideoURL] = useState(null);
  const [srtURL, setSrtURL] = useState();

  const [employes, setEmployes] = useState([]);
  const [projectName, setProjectName] = useState();
  const [projectOrginalLang, setProjectOrginalLang] =
    useState("Original Language");
  const [projectFinalLang, setProjectFinalLang] = useState("Final Languages");
  const [projectFinalLangs, setProjectFinalLangs] = useState([]);
  const [selectEmployee, setSelectEmployee] = useState("select an to add user");
  const [selectRole, setSelectRole] = useState(
    "select the role of the employee"
  );
  const [displaySelection, setDisplaySelection] = useState([]);
  const [upLoader, setUpLoader] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const [audioProgress, setAudioProgress] = useState(0);
  const [jsonSrtProgress, setJsonSrtProgress] = useState(0);

  const [videoOptions, setVideoOptions] = useState({
    choosenType: "file",
    drive: {
      error: false,
      url: "",
    },
    dropbox: {
      error: false,
      url: "",
    },
    file: {
      error: false,
      filePath: "",
    },
  });
  const [srtData, setSrtData] = useState(false);

  const [showAudioAddingOption, setShowAudioAddingOption] = useState(false);
  const [showAddingSrtFileOption, setShowAddingSrtFileOption] = useState(false);

  const [channelType, setChannelType] = useState("");
  const [audioType, setAudioType] = useState("");
  const [uploadType, setUploadType] = useState("");
  const [selectUrlFile, setSelectUrlFile] = useState();

  const [uploadedAudioTypes, setUploadedAudioTypes] = useState([]);

  const [facesToggle, setFacesToggle] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const employes = await axios.get(GET_USERS);
        setEmployes(employes.data.usersArray);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  // submission api
  const handleSubmitCreation = async (e) => {
    let originalVideoDetails = { width: 600, height: 337, videoFPS: 25 };

    const officalDialogueAudioUrl = uploadedAudioTypes.find(
      (audio) => audio.audioType === "dialogue"
    );

    if (!videoURL || !projectFinalLangs) {
      toast.error("you should upload the video first!!");
      return;
    }

    if (!officalDialogueAudioUrl) {
      toast.error("Project can't be created without any dialogue audio!!");

      return;
    }

    setUpLoader(true);
    // e.preventDefault();

    try {
      const response = await axios.post(CREATE_PROJECT, {
        employes: displaySelection,
        name: projectName,
        orginalLang: projectOrginalLang,
        finalLang: projectFinalLangs,
        videoUrl: videoURL,
        dialogueAudioUrl: officalDialogueAudioUrl?.url
          ? officalDialogueAudioUrl?.url
          : "",
        uploadedAudioTypes: uploadedAudioTypes,
        originalVideoDetails: originalVideoDetails,
      });
      console.log("Project has been added");
      toast.success("Project has been added");

      if (officalDialogueAudioUrl.url) {
        axios.post(TRANSCRIBE, {
          url: officalDialogueAudioUrl.url,
          projectId: response.data.projectId,
          language: projectOrginalLang,
        });
      }

      if (facesToggle) {
        axios.post(FACE_DETECTOR, {
          videoUrl: videoURL,
          projectName: projectName,
        });
      }

      navigate("/adminlanding");
      setUpLoader(false);
    } catch (error) {
      console.log(error);
      setUpLoader(false);
    }
  };

  //upload srt file as a json file
  const handleJsonUpload = () => {
    if (!projectName) {
      toast.error("you should fill the project name");
      return;
    }

    const jsonData = JSON.stringify(srtData);

    const blob = new Blob([jsonData], { type: "application/json" });

    const fileName = `${projectName}/Srt-${Date.now()}.json`;

    const storageRef = ref(storage, fileName);

    const uploadTask = uploadBytesResumable(storageRef, blob);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setJsonSrtProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((URL) => {
          toast.success("Srt JSON file has been uploaded successfully");
          setSrtURL(URL);
        });
      }
    );
  };

  // video file uploading
  const handelVideoUpload = () => {
    if (!projectName) {
      toast.error("you should fill the project name");
      return;
    }

    toast.info("Video is being uploaded wait for it");

    const videoFile = videoOptions.file.filePath;
    const fileName = `${projectName}/${videoFile.name}`;

    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, videoFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setVideoProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is pause");
            break;
          case "running":
            console.log("Upload is Running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((URL) => {
          toast.success("Video his been uploaded successfully");
          setVideoOptions((prevMode) => ({
            ...prevMode,
            file: {
              ...prevMode.file,
              filePath: "",
            },
          }));

          setVideoURL(URL);
        });
      }
    );
  };

  // video google drive url uploading
  const handleVideoUploadDrive = async (e) => {
    setUpLoader(true);
    const videoDriveUrl = videoOptions.drive.url;

    if (!videoDriveUrl) {
      toast.error("you should fill the video drive url first");
      setUpLoader(false);

      return;
    }

    if (!projectName) {
      toast.error("you should fill the project name");
      setUpLoader(false);
      return;
    }

    try {
      const response = await axios.post(UPLOAD_VIDEO_DRIVE, {
        projectName: projectName,
        url: videoDriveUrl,
      });
      const videoUrl = `https://storage.googleapis.com/video-dub-e0a3e.appspot.com/${response.data.videoPath}`;
      setVideoURL(videoUrl);
      toast.success("Video is being uploaded");

      setUpLoader(false);
    } catch (error) {
      console.log(error);
      toast.error("Error while uploading the video");

      setUpLoader(false);
    }
  };

  // video drop box url uploading
  const handleVideoUploadDropbox = async (e) => {
    setUpLoader(true);

    const videoDropboxUrl = videoOptions.dropbox.url;

    if (!videoDropboxUrl) {
      toast.error("you should fill the video dropbox url first");
      setUpLoader(false);

      return;
    }

    if (!projectName) {
      toast.error("you should fill the project name");
      setUpLoader(false);

      return;
    }

    try {
      const response = await axios.post(UPLOAD_VIDEO_DROPBOX, {
        projectName: projectName,
        url: videoDropboxUrl,
      });
      // console.log(response.data);
      const videoUrl = `https://storage.googleapis.com/video-dub-e0a3e.appspot.com/${response.data.videoPath}`;
      setVideoURL(videoUrl);

      toast.success("Video is being uploaded");

      setUpLoader(false);
    } catch (error) {
      console.log(error);
      setUpLoader(false);
    }
  };

  // audio file uploading
  const handleDirectAudioUpload = () => {
    if (!projectName) {
      toast.error("you should fill the project name");
      return;
    }
    toast.info("file is being uploaded wait for it");

    const audioFile = selectUrlFile;
    const fileName = `${projectName}/${projectName}_${audioType}_${channelType}.wav`;
    // const fileName = `${projectName}/${audioFile.name}_${audioType}_${channelType}`;

    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, audioFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setAudioProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is pause");
            break;
          case "running":
            console.log("Upload is Running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((URL) => {
          toast.success(`${audioType} file his been uploaded successfully`);

          setUploadedAudioTypes((prev) => [
            ...prev,
            {
              channelType: channelType,
              audioType: audioType,
              uploadType: uploadType,
              url: URL,
            },
          ]);

          setChannelType("");
          setAudioType("");
          setUploadType("");
          setSelectUrlFile("");
          setAudioProgress(0);
          setShowAudioAddingOption(false);
        });
      }
    );
  };
  // audio  url uploading
  const handleUrlAudioUpload = async (uploadMethod) => {
    if (!selectUrlFile) {
      toast.error("you should fill the url box");
      return;
    }

    if (!projectName) {
      toast.error("you should fill the project name");
      return;
    }
    if (!channelType) {
      toast.error("choose a channel type");
      return;
    }

    toast.info("Audio url is uploading, wait for it");

    try {
      let response;
      if (uploadMethod === "googleDrive") {
        response = await axios.post(UPLOAD_AUDIO_DRIVE, {
          projectName: projectName,
          url: selectUrlFile,
          type: `${audioType}_${channelType}`,
        });
      }

      if (uploadMethod === "dropbox") {
        response = await axios.post(UPLOAD_AUDIO_DROPBOX, {
          projectName: projectName,
          url: selectUrlFile,
          type: `${audioType}_${channelType}`,
        });
      }

      setUploadedAudioTypes((prev) => [
        ...prev,
        {
          channelType: channelType,
          audioType: audioType,
          uploadType: uploadType,
          url: response.data.url,
        },
      ]);

      setChannelType("");
      setAudioType("");
      setUploadType("");
      setSelectUrlFile("");
      setShowAudioAddingOption(false);
      // console.log(response.data.url);
      // setAudioUrl(response.data.url);
      toast.success("Audio dialogue his been uploaded successfully");

      setUpLoader(false);
    } catch (error) {
      console.log(error);
      toast.error("Error while uploading Audio dialogue");
      setUpLoader(false);
    }
  };

  function isValidUrl(url) {
    // Regular expression for URL validation
    if (!channelType) {
      return false;
    }
    if (!audioType) {
      return false;
    }

    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    // Test the input string against the regex
    return urlRegex.test(url);
  }

  function isValidUrlForVideo(url) {
    // Regular expression for URL validation

    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    // Test the input string against the regex
    return urlRegex.test(url);
  }

  const handleRemoveUploadedFile = (url) => {
    // console.log(url);
    const newItems = uploadedAudioTypes.filter((item) => item.url !== url);
    console.log(newItems);
    setUploadedAudioTypes(newItems);
  };

  const handleRemoveEmployes = (name) => {
    const newItems = displaySelection.filter((item) => item.name !== name);
    setDisplaySelection(newItems);
  };

  const handleRemoveLanguage = (code) => {
    const newItems = projectFinalLangs.filter(
      (languageCode) => languageCode !== code
    );
    setProjectFinalLangs(newItems);
  };

  return (
    <Grid
      item
      container
      justifyContent="center"
      style={{ fontFamily: "var(--global-text-font)" }}
    >
      {/* {console.log(uploadedAudioTypes)} */}
      {console.log(videoURL)}

      <Loader Load={upLoader} />
      <ToastContainer position="top-center" theme="dark" />

      <Grid item container xs={9} marginTop={7} direction="column">
        <Grid>
          <Grid item marginBottom={5} xs={3} className={classes["header"]}>
            Create Project
          </Grid>

          <Grid item xs={8} marginBottom={3}>
            <TextField
              width="100%"
              type="text"
              value={projectName}
              label="Project Name"
              onChange={(e) => setProjectName(e.target.value)}
            />
          </Grid>

          <Grid item xs={8} marginBottom={3}>
            <FormControl style={{ width: "220px" }}>
              {/* <InputLabel> Original Language</InputLabel> */}
              <Select
                value={projectOrginalLang}
                onChange={(e) => setProjectOrginalLang(e.target.value)}
              >
                <MenuItem value="Original Language" disabled={true}>
                  Original Language
                </MenuItem>

                {languagesCode.map((language) => (
                  <MenuItem key={language.code} value={language.code}>
                    {language.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            container
            xs={12}
            marginBottom={3}
            alignItems="center"
            direction="row"
            gap={5}
          >
            <Grid item>
              <FormControl style={{ width: "220px" }}>
                {/* <InputLabel>Final Languages</InputLabel> */}
                <Select
                  value={projectFinalLang}
                  onChange={(e) => {
                    // setProjectFinalLang(e.target.value);
                    setProjectFinalLangs([
                      ...projectFinalLangs,
                      e.target.value,
                    ]);
                    setProjectFinalLang("");
                  }}
                  // label={"Select Final Languages"}
                >
                  <MenuItem value="Final Languages" disabled={true}>
                    Final Languages
                  </MenuItem>

                  {languagesCode.map((language) => (
                    <MenuItem key={language.code} value={language.code}>
                      {language.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item marginLeft={2} xs={1} fontSize="10px">
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  if (projectFinalLang === "") return;
                  setProjectFinalLangs([
                    ...projectFinalLangs,
                    projectFinalLang,
                  ]);
                  setProjectFinalLang("");
                }}
                size="small"
              >
                Add
              </Button>
            </Grid> */}

            <Grid item>
              <Grid container gap={1}>
                {projectFinalLangs.map((language, index) => (
                  <Grid
                    item
                    key={index}
                    sx={{
                      bgcolor: "lightblue",
                      minWidth: "70px",
                      borderRadius: "10px",
                    }}
                  >
                    <Grid item marginLeft={1}>
                      {getLanguageName(language)}
                    </Grid>
                    <Grid item marginLeft={2}>
                      <IconButton
                        onClick={() => handleRemoveLanguage(language)}
                        size="small"
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          {/* {console.log(videoOptions)} */}

          <Grid
            container
            direction="column"
            spacing={2}
            marginBottom={5}
            marginTop={5}
          >
            <Grid item className={classes["midHeader"]}>
              Video Options
            </Grid>
            <Grid item>
              <Grid
                container
                onChange={(e) =>
                  setVideoOptions({
                    choosenType: e.target.value,
                    drive: {
                      error: false,
                      url: "",
                    },
                    dropbox: {
                      error: false,
                      url: "",
                    },
                    file: {
                      error: false,
                      filePath: "",
                    },
                  })
                }
                gap={4}
                marginBottom={3}
              >
                <Grid item>
                  <input
                    type="radio"
                    value="file"
                    checked={videoOptions.choosenType === "file"}
                  />
                  <label htmlFor="file">Upload File</label>
                </Grid>

                <Grid item>
                  <input
                    type="radio"
                    value="dropbox"
                    checked={videoOptions.choosenType === "dropbox"}
                  />
                  <label htmlFor="dropbox">Dropbox URL</label>
                </Grid>

                <Grid>
                  <input
                    type="radio"
                    value="google-drive"
                    checked={videoOptions.choosenType === "google-drive"}
                  />
                  <label htmlFor="google-drive">Google Drive URL</label>
                </Grid>
              </Grid>
              {videoOptions.choosenType === "file" && (
                <Grid item container direction="row">
                  <Grid item xs={2.2}>
                    <input
                      className={classes["file-upload-button"]}
                      type="file"
                      onChange={(e) => {
                        setVideoOptions((prevMode) => ({
                          ...prevMode,
                          file: {
                            ...prevMode.file,
                            filePath: e.target.files[0],
                          },
                        }));
                        setVideoURL(null);
                        setVideoProgress(0);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TaskButton
                      disabled={videoURL}
                      onClick={() => handelVideoUpload()}
                      variant="outlined"
                    >
                      Upload
                    </TaskButton>
                    {" " + Math.floor(videoProgress)}%
                    {videoProgress === 100 ? (
                      <> its has been uploaded successfully</>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              )}

              {videoOptions.choosenType === "dropbox" && (
                <Grid item container gap={3}>
                  <Grid item xs={2}>
                    <input
                      className={classes["input"]}
                      type="text"
                      placeholder="Dropbox URL"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setVideoOptions((prevMode) => ({
                          ...prevMode,
                          dropbox: {
                            ...prevMode.file,
                            url: e.target.value,
                            error: isValidUrlForVideo(e.target.value),
                          },
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TaskButton
                      disabled={!videoOptions.dropbox.error}
                      onClick={() => handleVideoUploadDropbox()}
                    >
                      Upload
                    </TaskButton>
                  </Grid>
                  {videoURL && videoOptions.dropbox.url ? (
                    <> link has been submitted successfully</>
                  ) : (
                    <></>
                  )}
                </Grid>
              )}
              {videoOptions.choosenType === "google-drive" && (
                <Grid item container gap={3}>
                  <Grid item xs={2}>
                    <input
                      style={{ width: "100%" }}
                      className={classes["input"]}
                      type="text"
                      placeholder="Google Drive URL"
                      onChange={(e) => {
                        setVideoOptions((prevMode) => ({
                          ...prevMode,
                          drive: {
                            ...prevMode.file,
                            url: e.target.value,
                            error: isValidUrlForVideo(e.target.value),
                          },
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TaskButton
                      disabled={!videoOptions.drive.error}
                      onClick={() => handleVideoUploadDrive()}
                    >
                      Upload
                    </TaskButton>
                  </Grid>
                  {videoURL && videoOptions.drive.url ? (
                    <> link has been submitted successfully</>
                  ) : (
                    <></>
                  )}
                </Grid>
              )}

              {/* {videoURL && <>Url: {videoURL}</>} */}
            </Grid>
          </Grid>

          {/* {console.log(audiomethod)} */}

          <Grid
            container
            direction="column"
            spacing={2}
            marginBottom={15}
            marginTop={5}
          >
            <Grid item className={classes["midHeader"]}>
              Audio Options
            </Grid>

            <Grid item xs={12} container direction="row" gap={4}>
              <Grid item xs={1}>
                <TaskButton onClick={() => setShowAudioAddingOption(true)}>
                  Add Audio
                </TaskButton>
              </Grid>
              {showAudioAddingOption && (
                <>
                  <Grid item xs={1}>
                    <Grid item>
                      <select
                        className={classes["selector"]}
                        value={channelType}
                        onChange={(e) => {
                          setChannelType(e.target.value);
                        }}
                      >
                        <option value="">channels</option>
                        <option value="right">right</option>
                        <option value="left">left</option>
                        <option value="multi-channel">multi-channel</option>
                      </select>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} marginRight={8}>
                    <Grid item>
                      <select
                        className={classes["selector"]}
                        value={audioType}
                        onChange={(e) => {
                          setAudioType(e.target.value);
                        }}
                      >
                        <option value="">Type</option>
                        <option value="dialogue">Dialogue</option>
                        <option value="music">Music</option>
                        <option value="effect">Effect</option>
                        <option value="musicEffects">Music & Effects</option>
                        <option value="dialogueMusicEffect">
                          Dialogue, Music, Effects
                        </option>
                      </select>
                    </Grid>
                  </Grid>
                  <Grid item container xs={6} direction="row">
                    <Grid item xs={3.7}>
                      <select
                        className={classes["selector"]}
                        value={uploadType}
                        onChange={(e) => {
                          setUploadType(e.target.value);
                        }}
                      >
                        <option value="">Upload Method</option>
                        <option value="directUpload">Direct Upload</option>
                        <option value="googleDrive">Google Drive</option>
                        <option value="dropbox">Dropbox</option>
                      </select>
                    </Grid>
                    <Grid item xs={8}>
                      {uploadType === "directUpload" && (
                        <Grid item container direction="row">
                          <Grid item xs={6}>
                            <input
                              className={classes["file-upload-button"]}
                              type="file"
                              onChange={(e) => {
                                setSelectUrlFile(e.target.files[0]);
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TaskButton
                              disabled={
                                !selectUrlFile?.type ||
                                !channelType ||
                                audioType === ""
                              }
                              onClick={() => handleDirectAudioUpload()}
                            >
                              Upload
                            </TaskButton>
                            {" " + Math.floor(audioProgress)}%
                            {audioProgress === 100 ? (
                              <> its has been uploaded successfully</>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </Grid>
                      )}
                      {uploadType === "googleDrive" && (
                        <Grid item container direction="row">
                          <Grid item xs={6}>
                            <input
                              className={classes["input"]}
                              type="text"
                              placeholder="Google Drive URL"
                              onChange={(e) => {
                                setSelectUrlFile(e.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TaskButton
                              disabled={!isValidUrl(selectUrlFile)}
                              onClick={() =>
                                handleUrlAudioUpload("googleDrive")
                              }
                            >
                              Upload
                            </TaskButton>
                          </Grid>
                        </Grid>
                      )}
                      {uploadType === "dropbox" && (
                        <Grid item container direction="row">
                          <Grid item xs={6}>
                            <input
                              className={classes["input"]}
                              type="text"
                              placeholder="Dropbox URL"
                              onChange={(e) => {
                                setSelectUrlFile(e.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TaskButton
                              disabled={!isValidUrl(selectUrlFile)}
                              onClick={() => handleUrlAudioUpload("dropbox")}
                            >
                              Upload
                            </TaskButton>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>

            {uploadedAudioTypes.length > 0 && (
              <Grid item marginTop={8}>
                {/* <Grid item>Uploaded file</Grid> */}
                <table
                  border="1"
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                  }}
                >
                  <thead>
                    <tr>
                      <th>Channel Type</th>
                      <th>Audio Type</th>
                      <th>Upload Type</th>
                      <th>URL</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {uploadedAudioTypes.map((audio, index) => (
                      <tr key={index}>
                        <td>{audio.channelType}</td>
                        <td>{audio.audioType}</td>
                        <td>{audio.uploadType}</td>
                        <td>
                          <a
                            href={audio.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {audio.url}
                          </a>
                        </td>
                        <td>
                          <Button
                            color="error"
                            onClick={() => handleRemoveUploadedFile(audio.url)}
                          >
                            delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Grid>
            )}
          </Grid>

          {/* {console.log(Srt method)} */}

          <Grid
            container
            direction="column"
            spacing={2}
            marginBottom={15}
            marginTop={5}
          >
            <Grid item className={classes["midHeader"]}>
              Transcription Options
            </Grid>

            <Grid item xs={12} container direction="row" gap={4}>
              <Grid item xs={1}>
                <TaskButton onClick={() => setShowAddingSrtFileOption(true)}>
                  Add SRT file
                </TaskButton>
              </Grid>
              {showAddingSrtFileOption && (
                <Grid item container direction="row" xs={10}>
                  <Grid item xs={2.7}>
                    <SRTSubtitleParser setSrtData={setSrtData} />
                  </Grid>
                  <Grid item xs={2}>
                    <TaskButton
                      disabled={!srtData}
                      onClick={() => handleJsonUpload()}
                      variant="outlined"
                    >
                      Upload
                    </TaskButton>
                    {" " + Math.floor(jsonSrtProgress)}%
                    {jsonSrtProgress === 100 ? (
                      <> its has been uploaded successfully</>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* {console.log(Dubbing options)} */}

          <Grid
            container
            direction="column"
            spacing={2}
            marginBottom={15}
            marginTop={5}
          >
            <Grid item className={classes["midHeader"]}>
              Dubbing Options
            </Grid>

            <Grid item xs={12} container direction="column" gap={1}>
              <Grid item xs={2}>
                <label>
                  <input
                    type="checkbox"
                    checked={facesToggle}
                    onChange={(e) => setFacesToggle(e.target.checked)}
                  />
                  Lip dubbing
                </label>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={10}
            marginBottom={3}
            gap={3}
            alignItems="center"
            direction="row"
          >
            <Grid item className={classes["midHeader"]} marginBottom={1.5}>
              Project Members
            </Grid>
            <Grid item container alignItems="center" direction="row" gap={3}>
              <Grid item>
                <Select
                  value={selectEmployee}
                  color="primary"
                  onChange={(event) => setSelectEmployee(event.target.value)}
                  defaultValue="Select Employee"
                >
                  <MenuItem value="select an to add user" disabled>
                    Select an employee to add to project
                  </MenuItem>
                  {employes.map((employe, index) => (
                    <MenuItem key={index} value={employe.name}>
                      {employe.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item>
                <Select
                  value={selectRole}
                  multiline
                  onChange={(event) => setSelectRole(event.target.value)}
                >
                  <MenuItem value="select the role of the employee" disabled>
                    select the role of employee
                  </MenuItem>
                  <MenuItem value="transcriber">
                    {getTaskName("transcriber")}
                  </MenuItem>
                  <MenuItem value="translator">
                    {getTaskName("translator")}
                  </MenuItem>
                  <MenuItem value="voicequalityassurance">
                    {getTaskName("voicequalityassurance")}
                  </MenuItem>
                  <MenuItem value="speakerfaceidentification">
                    {getTaskName("speakerfaceidentification")}
                  </MenuItem>
                  <MenuItem value="lipqualityassurance">
                    {getTaskName("lipqualityassurance")}
                  </MenuItem>
                  <MenuItem value="qualityassurance">
                    {getTaskName("qualityassurance")}
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item>
                <GlobalButton
                  onClick={() => {
                    if (selectEmployee === "select an to add user") return;
                    if (selectRole === "select the role of the employee")
                      return;

                    const employeid = employes.find(
                      (employee) => employee.name === selectEmployee
                    );

                    const newItem = {
                      name: selectEmployee,
                      role: selectRole,
                      id: employeid.id,
                    };
                    setDisplaySelection([...displaySelection, newItem]);
                  }}
                >
                  Add Employee
                </GlobalButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container direction="column" marginTop={5}>
            {/* <Grid item className={classes["midHeader"]} marginBottom={1.5}>
              Project Members
            </Grid> */}
            <Grid item container xs={10} marginBottom={3}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>NAME</TableCell>
                    <TableCell>ROLE</TableCell>
                    <TableCell>ACTIONS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displaySelection.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{getTaskName(user.role)}</TableCell>
                      <TableCell>
                        <Button
                          color="error"
                          variant="contained"
                          onClick={() => handleRemoveEmployes(user.name)}
                        >
                          Remove
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>

          <Grid item container direction="column" marginTop={5}>
            {/* <Grid
              item
              sx={{
                fontSize: "20px",
                fontWeight: "600",
                // borderBottom: "1px solid #ebebeb",
              }}
              marginBottom={1.5}
              marginLeft={2}
            >
              Finish
            </Grid> */}
            <Grid item>
              <GlobalButton
                variant="contained"
                onClick={() => handleSubmitCreation()}
              >
                CREATE
              </GlobalButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectCreationScreen;
