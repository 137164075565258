import { languagesCode } from "../Data/LanguagesCode.js";
import { tasksCode, tasksCodeFormal } from "../Data/tasksCode.js";
import { getDownloadUrlForFileWithoutHandler } from "../config/firebase.js";

export function getLanguageName(code) {
  const language = languagesCode.find((lang) => lang.code === code);
  return language ? language.name : "Unknown Language";
}
export function getTaskName(code) {
  const task = tasksCode.find((task) => task.code === code);
  return task ? task.name : "Unknown task";
}
export function getTaskNameFormal(code) {
  const task = tasksCodeFormal.find((task) => task.code === code);
  return task ? task.name : "Unknown task";
}

export function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toFixed(2).padStart(5, "0"); // Ensures 2 decimal places

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export function timeStringToSeconds(timeString) {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);

  const totalSeconds = hours * 3600 + minutes * 60 + seconds;

  return totalSeconds;
}

export function convertToDate(creationDate) {
  if (creationDate) {
    const millisecondsFromSeconds = creationDate.seconds * 1000;
    const millisecondsFromNanoseconds = creationDate.nanoseconds / 1000000;
    const totalMilliseconds =
      millisecondsFromSeconds + millisecondsFromNanoseconds;
    const dateObject = new Date(totalMilliseconds);
    const formattedDate =
      dateObject.getUTCFullYear() +
      "-" +
      String(dateObject.getUTCMonth() + 1).padStart(2, "0") +
      "-" +
      String(dateObject.getUTCDate()).padStart(2, "0");

    return formattedDate;
  } else return;
}

export async function processPathUrl(input) {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
  );

  if (urlPattern.test(input)) {
    return input;
  } else {
    try {
      const url = await getDownloadUrlForFileWithoutHandler(input);
      return url;
    } catch (error) {
      // console.error("Error fetching download URL:");
      return "loading";
    }
  }
}
