import {
  Button,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Store } from "../Store";
import { getProjectById } from "../MProject-services.proxy";
import {
  convertToDate,
  getLanguageName,
  getTaskNameFormal,
  processPathUrl,
} from "../utils/generalFunctions";
import Loader from "../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import GlobalButton from "../Components/Buttons/GlobalButton";
import classes from "../Assets/Styles/Global.module.css";
import { secondaryColor } from "../utils/constants";

const workTypes = [
  "transcribe",
  "translate",
  "voicequalityassurance",
  "speakerfaceidentification",
  "lipqualityassurance",
  "qualityassurance",
];

const WorkPagesScreen = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { id: projectId } = params;
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [projectDetails, setProjectDetails] = useState([]);
  const [dataLoader, setDataLoader] = useState(true);

  const handleWork = (project, role, language) => {
    ctxDispatch({ type: "PROJECT_FILE", payload: project });
    localStorage.setItem("projectFile", JSON.stringify(project));

    if (role === "transcribe") navigate("/work/transcriber");
    else if (role === "translate") navigate(`/work/translator/${language}`);
    else if (role === "voicequalityassurance")
      navigate(`/work/voicequalityassurance/${language}`);
    else if (role === "lipqualityassurance")
      navigate(`/work/lipqualityassurance/${language}`);
    else if (role === "speakerfaceidentification")
      navigate("/work/speakerfaceidentification");
    else if (role === "qualityassurance")
      navigate(`/work/qualityassurance/${language}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProjectById(projectId);
        // let projectData = response.data;

        // let videoUrl = response.data.videoUrl;

        // projectData.videoUrl = await processPathUrl(videoUrl);

        // if (projectData.videoUrl === "loading") {
        //   toast.error("The uploaded video didn't finish loading yet");
        // }

        setProjectDetails(response.data);
        setDataLoader(false);
      } catch (err) {
        console.error("Error fetching project details:", err);
        setDataLoader(false);
      }
    };

    fetchData();
  }, [projectId]);

  return (
    <Grid item container justifyContent="center">
      {/* {console.log(projectDetails)} */}

      <Loader Load={dataLoader} use={userInfo} />
      <ToastContainer position="top-center" theme="dark" />
      <Grid
        item
        container
        xs={9.5}
        marginTop={7}
        direction="row"
        marginBottom={10}
      >
        <Grid item container direction="row" marginBottom={8.0} gap={2}>
          <Grid item className={classes["header"]}>
            {projectDetails.name} (Work Pages)
          </Grid>
          <Grid item marginTop={0.5}>
            <GlobalButton
              onClick={() => navigate(`/workpages/details/${projectId}`)}
            >
              Details
            </GlobalButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Project Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>ACTIONS</TableCell>
                <TableCell>View Original Content</TableCell>
                <TableCell>Original Language</TableCell>
                <TableCell>Final Languages</TableCell>
                <TableCell>Creation Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workTypes.map((type, index) => (
                <TableRow key={index}>
                  <TableCell>{projectDetails.name}</TableCell>
                  <TableCell>{getTaskNameFormal(type)}</TableCell>
                  <TableCell>
                    {type === "translate" ||
                    type === "voicequalityassurance" ||
                    type === "qualityassurance" ||
                    type === "lipqualityassurance" ? (
                      <Select
                        size="small"
                        color="primary"
                        defaultValue="Start Working"
                      >
                        <MenuItem value="Start Working">Start Working</MenuItem>
                        {projectDetails.finalLang?.map((language, index) => (
                          <MenuItem
                            key={index}
                            value={language}
                            onClick={() =>
                              handleWork(projectDetails, type, language)
                            }
                          >
                            {getLanguageName(language)}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <GlobalButton
                        color="primary"
                        onClick={() => {
                          handleWork(projectDetails, type);
                        }}
                        width="150px"
                      >
                        Start Working
                      </GlobalButton>
                    )}
                  </TableCell>

                  <TableCell>
                    <a
                      style={{
                        textDecoration: "none",
                        color: `${secondaryColor}`,
                      }}
                      href={projectDetails.videoUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Original Content
                    </a>
                  </TableCell>
                  <TableCell>
                    {getLanguageName(projectDetails.orginalLang)}
                  </TableCell>
                  <TableCell width="30%">
                    <Grid
                      item
                      container
                      direction="row"
                      gap={1}
                      alignItems="center"
                      justifyContent="center"
                      // bgcolor="red"
                    >
                      {projectDetails.finalLang?.map((language, index) => (
                        <Grid
                          xs={5.5}
                          item
                          container
                          key={index}
                          alignItems="center"
                          justifyContent="center"
                          width="300px"
                          height="32px"
                          sx={{
                            // background: `${secondaryColor}`,
                            background: "lightgrey",
                            fontWeight: "400",
                            borderRadius: "5px",
                          }}
                        >
                          <Grid item>{getLanguageName(language)}</Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </TableCell>
                  <TableCell>
                    {convertToDate(projectDetails.creationDate)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WorkPagesScreen;
