import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "../Assets/Styles/Header.module.css";
import { Button, Grid, Menu, MenuItem } from "@mui/material";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import HelpIcon from "@mui/icons-material/Help";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PersonIcon from "@mui/icons-material/Person";
import logo from "../Assets/Images/logo.png";

import { Store } from "../Store";

const labelColors = "var(--global-color)";

const Header = () => {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();

  const signoutHandler = () => {
    ctxDispatch({ type: "USER_SIGNOUT" });
    localStorage.removeItem("userInfo");
    localStorage.removeItem("projectFile");
    window.location.href = "/";
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [adminAnchorEl, setAdminAnchorEl] = React.useState(null);
  const openAdmin = Boolean(adminAnchorEl);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {userInfo ? (
        <Grid
          item
          container
          className={classes["headerContainer"]}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            item
            container
            alignContent="center"
            alignItems="center"
            xs={3}
            marginLeft={5}
            onClick={() => {
              userInfo.isAdmin
                ? navigate("/adminlanding")
                : navigate("/landing");
            }}
            sx={{ cursor: "pointer" }}
            gap={1}
          >
            <Grid item>
              <img src={logo} alt="logo" className={classes["logo"]} />
            </Grid>
            <Grid item className={classes["logoName"]}>
              DubFrog
            </Grid>
          </Grid>
          <Grid item marginRight={10}>
            <Grid item container alignItems="center" direction="row">
              {/* <Grid item marginRight={5}>
                <HelpIcon fontSize="large" className={classes["icons"]} />
              </Grid>
              <Grid item marginRight={5}>
                <CircleNotificationsIcon
                  className={classes["icons"]}
                  fontSize="large"
                />
              </Grid> */}
              <Grid item marginRight={0.3}>
                <PersonIcon className={classes["icons"]} fontSize="large" />
              </Grid>
              <Grid item className={classes["textFont"]}>
                <Button
                  onClick={handleClick}
                  style={{
                    color: `${labelColors}`,
                    fontFamily: "Livvic",
                    textTransform: "none",
                  }}
                >
                  {userInfo.name} <ArrowDropDownIcon />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    style: {
                      width: 144,
                    },
                  }}
                >
                  <MenuItem onClick={handleClose} sx={{ fontFamily: "Livvic" }}>
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={signoutHandler}
                    sx={{ fontFamily: "Livvic" }}
                  >
                    Sign out
                  </MenuItem>
                </Menu>
              </Grid>

              {(() => {
                if (userInfo.isAdmin) {
                  return (
                    <Grid item marginRight={7}>
                      <Button
                        onClick={(event) => {
                          setAdminAnchorEl(event.currentTarget);
                        }}
                        style={{
                          color: `${labelColors}`,
                          fontFamily: "Livvic",
                          textTransform: "none",
                        }}
                      >
                        Admin <ArrowDropDownIcon />
                      </Button>
                      <Menu
                        anchorEl={adminAnchorEl}
                        open={openAdmin}
                        onClose={() => setAdminAnchorEl(null)}
                        MenuListProps={{
                          style: {
                            width: 100,
                          },
                        }}
                      >
                        <MenuItem onClick={() => setAdminAnchorEl(null)}>
                          <Link
                            style={{
                              textDecoration: "none",
                              color: `${labelColors}`,
                              fontFamily: "Livvic",
                            }}
                            to="/admin/users"
                          >
                            Users
                          </Link>
                        </MenuItem>
                      </Menu>
                    </Grid>
                  );
                } else {
                  return <></>;
                }
              })()}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          container
          className={classes["headerContainer"]}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            item
            container
            alignContent="center"
            alignItems="center"
            xs={3}
            marginLeft={5}
            gap={1}
          >
            <Grid item>
              <img src={logo} alt="logo" className={classes["logo"]} />
            </Grid>
            <Grid item className={classes["logoName"]}>
              DubFrog
            </Grid>
          </Grid>
          {/* <Grid item>
            <Grid
              item
              container
              alignItems="center"
              direction="row"
              // justifyContent="space-between"
            >
              <Grid item marginRight={5}>
                <HelpIcon fontSize="large" className={classes["icons"]} />
              </Grid>
              <Grid item marginRight={5}>
                <CircleNotificationsIcon
                  fontSize="large"
                  className={classes["icons"]}
                />
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      )}
    </>
  );
};

export default Header;
