import { Grid } from "@mui/material";
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import classes from "../../Assets/Styles/QAScreen.module.css";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SquareIcon from "@mui/icons-material/Square";
import Loader from "../../Components/Loader";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Store } from "../../Store";
import {
  ADD_ONE_QUALITYASSURANCE_NOTE,
  DELETE_ONE_QUALITYASSURANCE_NOTE,
  GET_ALL_QUALITYASSURANCE_NOTES,
  GET_ALL_QUALITYASSURANCE_VERSIONS,
  UPDATE_ONE_QUALITYASSURANCE_NOTE,
} from "../../MProjectConst";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  convertToDate,
  formatTime,
  timeStringToSeconds,
} from "../../utils/generalFunctions";
import QAVideoSlider from "../../Components/AudioTools/QAVideoSlider";
import { primaryColor, secondaryColor } from "../../utils/constants";
import CopyLinkButton from "../../Components/CopyLinkButton";

const QAScreen = () => {
  const params = useParams();
  const { language: languageCode } = params;
  const { state } = useContext(Store);
  const { projectFile } = state;
  const [versionQA, setVersionQA] = useState();

  const [currentTime, setCurrentTime] = useState(0);
  const [seekTime, setSeekTime] = useState(0);
  const [seekTimeIndex, setSeekTimeIndex] = useState(0);
  const [scrollableItem, setScrollableItem] = useState(0);
  const [scrollableItemLastUpdated, setScrollableItemLastUpdated] = useState(0);
  const [audioLoader] = useState(false);
  const [dubbedVideosList, setDubbedVideosList] = useState([]);
  const [videoSource, setVidoSource] = useState(projectFile.videoUrl);
  const [videoUniqueId, setVideoUniqueId] = useState("");
  const [videoVersion, setVideoVersion] = useState("");

  const [startTimeText, setStartTimeText] = useState("00:00:00.00");
  const [endTimeText, setEndTimeText] = useState("00:00:00.00");
  const [desText, setDesText] = useState("");

  const containerRef = useRef(null);
  const timeoutIdRef = useRef(null);

  // pagination items
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(versionQA?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = versionQA?.slice(startIndex, endIndex);

  const goToPage = (page) => {
    setCurrentPage(page);
  };
  //-------------------------------------

  useEffect(() => {
    scrollToItem(scrollableItem - 1);

    if (scrollableItem - 1 >= 1 && scrollToItem) {
      // handlePauseTime();
    }
  }, [scrollableItem]);

  useEffect(() => {
    checkTimeInRange(currentTime);
  }, [currentTime]);

  const scrollToItem = (index) => {
    const manpIndex = currentPage >= 2 ? index - 10 * (currentPage - 1) : index;

    if (containerRef.current) {
      const item = containerRef.current.children[manpIndex];
      if (item) {
        item.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  const displayAudioTime = useCallback((data) => {
    setCurrentTime(data);
  }, []);

  function checkTimeInRange(currentTime) {
    const itemInTimeRange = versionQA?.find((item) => {
      // const { start, end } = item.textdetails;
      return currentTime >= item.start && currentTime < item.end;
    });

    if (itemInTimeRange) {
      if (itemInTimeRange.id !== scrollableItemLastUpdated) {
        setScrollableItemLastUpdated(itemInTimeRange.id);
        setScrollableItem(itemInTimeRange.id);
      }
    }
  }

  //+ Text api for dialogue
  function handleTextChange(e, index) {
    const updatedData = [...versionQA];
    updatedData[index] = {
      ...updatedData[index],
      description: e.target.value,
    };

    setVersionQA(updatedData);
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_QUALITYASSURANCE_NOTE, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 1650);
  }

  //+ Start time api for dialogue
  function handleStartTimeChange(e, index) {
    const updatedData = [...versionQA];
    updatedData[index] = {
      ...updatedData[index],
      start: timeStringToSeconds(e.target.value),
    };

    setVersionQA(updatedData);
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_QUALITYASSURANCE_NOTE, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 2300);
  }
  //+ End time api for dialogue
  function handleEndTimeChange(e, index) {
    const updatedData = [...versionQA];
    updatedData[index] = {
      ...updatedData[index],
      end: timeStringToSeconds(e.target.value),
    };

    setVersionQA(updatedData);
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_QUALITYASSURANCE_NOTE, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 2300);
  }

  const AddNewDialogue = async () => {
    if (endTimeText === "00:00:00") {
      // toast.error("Enter The Ending time for this sentence");
      return;
    }

    let updatedData = [...versionQA];

    const translations = [];
    projectFile.finalLang?.forEach((langCode) => {
      translations.push({
        languageCode: langCode,
        translatedText: "",
        translatedAudioList: [],
        translatedAudio: "",
        translatedAudioRating: "",
        translatedAudioNote: null,
        translatedAudioPath: null,
        translatedRecording: "",
      });
    });

    try {
      const response = await axios.post(ADD_ONE_QUALITYASSURANCE_NOTE, {
        projectId: projectFile.id,
        uniqueId: videoUniqueId,
        dialogue: {
          description: desText,
          end: timeStringToSeconds(endTimeText),
          start: timeStringToSeconds(startTimeText),
        },
      });
      if (response.data) {
        const NewDialogue = {
          description: desText,
          end: timeStringToSeconds(endTimeText),
          start: timeStringToSeconds(startTimeText),
          dbid: response.data,
        };

        const index = updatedData.findIndex(
          (obj) =>
            NewDialogue.start < obj.start ||
            (NewDialogue.start === obj.start && NewDialogue.end < obj.end)
        );

        if (index === -1) {
          updatedData.push(NewDialogue);
        } else {
          updatedData.splice(index, 0, NewDialogue); // Insert at the found index
        }

        // updatedData.push(NewDialogue);

        updatedData = updatedData.map((object, index) => ({
          ...object,
          add: false,
          id: index,
        }));

        // toast.success("A new dialogue has been added");

        setStartTimeText("00:00:00");
        setEndTimeText("00:00:00");

        setDesText("Write here");

        setVersionQA(updatedData);
      }
    } catch (err) {
      console.log(err);
      // toast.error("Error in adding the new dialogue");
    }
  };

  //+ delete api
  const removeDialogue = async (uid) => {
    try {
      const response = await axios.post(DELETE_ONE_QUALITYASSURANCE_NOTE, {
        projectId: projectFile.id,
        uid: uid,
      });

      if (response.data) {
        let newItems = versionQA.filter((dia) => dia.dbid !== uid);
        newItems = newItems.map((object, index) => ({
          ...object,
          id: index,
        }));
        setVersionQA(newItems);

        // toast.success("The dialogue has been deleted!");
      }
    } catch (err) {
      console.log(err);
      // toast.error("Error in deleting the dialogue");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(GET_ALL_QUALITYASSURANCE_VERSIONS, {
          projectId: projectFile.id,
          languageCode: languageCode,
        });

        const languageFilteration = response.data.dubbedVideosList?.filter(
          (item) => item.language === languageCode
        );

        // console.log(languageFilteration);
        // setVersionQA(sortedArray);
        setDubbedVideosList(languageFilteration);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  function fetchQaulityAssuranceVersion(uniqueId) {
    const fetchData = async () => {
      try {
        const response = await axios.post(GET_ALL_QUALITYASSURANCE_NOTES, {
          projectId: projectFile.id,
          uniqueId: uniqueId,
        });

        const sortedArray = await response.data.projectsArray.sort((a, b) => {
          if (a.start < b.start) return -1;
          if (a.start > b.start) return 1;
          if (a.end < b.end) return -1;
          if (a.end > b.end) return 1;

          return 0;
        });

        sortedArray.forEach((dia, index) => {
          dia.add = false;
          dia.id = index;
        });

        // console.log(sortedArray);

        setVersionQA(sortedArray);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }

  return (
    <Grid item container justifyContent="center" marginTop={3}>
      {/* {console.log(currentItems)} */}
      <Loader Load={audioLoader} />
      <Grid item container xs={11.5} direction="column">
        <Grid item className={classes["header"]}>
          Quality Assurance
        </Grid>
        <Grid item container direction="row">
          <Grid item container xs={5.85} direction="column" marginRight={2}>
            <Grid
              item
              container
              className={
                true ? classes["OnAudioFiles"] : classes["OffAudioFiles"]
              }
              direction="column"
            >
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                className={classes["AudioTitles"]}
                justifyContent="space-between"
              >
                <Grid item container marginLeft={1.5} xs={3} direction="row">
                  <Grid item marginRight={1}>
                    <AutoStoriesIcon fontSize="medium" />
                  </Grid>
                  <Grid item className={classes["AudioTitleFont"]}>
                    Version names
                  </Grid>
                </Grid>

                <Grid
                  item
                  fontSize="40px"
                  onClick={() => {
                    // setOpenCharactersFolder(!openCharactersFolder);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  {true ? (
                    <KeyboardArrowUpIcon fontSize="large" />
                  ) : (
                    <KeyboardArrowDownIcon fontSize="large" />
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                item
                className={classes["AudioFilesBoxScroller"]}
                xs={8}
              >
                <Grid
                  item
                  container
                  direction="row"
                  gap={3}
                  marginTop={1}
                  marginLeft={1}
                >
                  {dubbedVideosList?.map((video, index) => (
                    <Grid
                      key={index}
                      container
                      item
                      xs={11.8}
                      alignItems="center"
                      justifyContent="space-evenly"
                      height="40px"
                      bgcolor={
                        videoVersion === video.version
                          ? "red"
                          : `${secondaryColor}`
                      }
                      onClick={() => {
                        setVidoSource(video.url);
                        fetchQaulityAssuranceVersion(video.uniqueId);
                        setVideoVersion(video.version);
                        setVideoUniqueId(video.uniqueId);
                      }}
                    >
                      {/* <Grid item xs={0.1} color="#F5F5F5">
                        <SquareIcon />
                      </Grid> */}
                      <Grid item color="#FFFFFF" fontSize="17px">
                        {video.version}
                      </Grid>
                      <Grid item>
                        {" "}
                        <CopyLinkButton link={video.url} />
                      </Grid>
                      <Grid item color="#FFFFFF" fontSize="17px">
                        {convertToDate(video.creationDate)}
                      </Grid>

                      {/* <Grid
                        item
                        xs={2}
                        // onClick={() => removeCharacter(char.speaker)}
                        sx={{ cursor: "pointer" }}
                      >
                        <DeleteIcon />
                      </Grid> */}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={classes["AudioWorkPlace"]}
              marginTop={1}
              direction="column"
            >
              <Grid
                item
                container
                direction="row"
                // justifyContent="space-between"
                alignItems="center"
                className={classes["AudioTitles"]}
              >
                <Grid
                  item
                  marginRight={38}
                  marginLeft={4.5}
                  className={classes["AudioTitleFont"]}
                >
                  Video Version : {" " + videoVersion}
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item container direction="row" alignItems="center">
                  <Grid
                    item
                    marginRight={32}
                    marginLeft={5}
                    marginTop={2}
                    marginBottom={2}
                    className={classes["AudioWorkPlaceInsideTitle"]}
                  >
                    {/* {videoVersion} */}
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  className={classes["AudioWorkPlaceChatBoxScroller"]}
                  maxHeight={{
                    xl: "800px",
                    lg: "360px",
                    md: "800px",
                    xs: "360px",
                  }}
                  ref={containerRef}
                >
                  {versionQA ? (
                    <>
                      {versionQA.length > 0 ? (
                        <>
                          {currentItems.map((data, index) => (
                            <React.Fragment key={index}>
                              <Grid
                                item
                                container
                                direction="row"
                                alignItems="center"
                              >
                                <Grid
                                  item
                                  container
                                  direction="column"
                                  xs={1.8}
                                  marginLeft={1}
                                  className={classes["AudioWorkPlaceTimeline"]}
                                  height="60px"
                                  gap={0.5}
                                >
                                  <Grid item marginBottom={0}>
                                    <textarea
                                      className={
                                        classes["AudioWorkPlaceTimelineEdit"]
                                      }
                                      value={formatTime(data.start)}
                                      type="text"
                                      onChange={(e) =>
                                        handleStartTimeChange(e, data.id)
                                      }
                                    ></textarea>
                                  </Grid>
                                  <Grid item>
                                    <textarea
                                      className={
                                        classes["AudioWorkPlaceTimelineEdit"]
                                      }
                                      value={formatTime(data.end)}
                                      type="text"
                                      onChange={(e) =>
                                        handleEndTimeChange(e, data.id)
                                      }
                                    ></textarea>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  className={classes["AudioWorkPlaceBoxes"]}
                                  direction="column"
                                  gap={1}
                                  paddingLeft={1.5}
                                  paddingTop={1}
                                  xs={8}
                                  onClick={() => {
                                    setSeekTime(data.start);
                                    setSeekTimeIndex(seekTimeIndex + 1);
                                  }}
                                  sx={{ cursor: "pointer" }}
                                >
                                  <Grid
                                    item
                                    container
                                    alignContent="center"
                                    direction="row"
                                  >
                                    <Grid item xs={12}>
                                      <textarea
                                        className={
                                          classes["AudioWorkTextEditInput"]
                                        }
                                        style={{
                                          color:
                                            currentTime >= data.start &&
                                            currentTime < data.end
                                              ? "red"
                                              : "",
                                        }}
                                        value={data.description}
                                        type="text"
                                        onChange={(e) =>
                                          handleTextChange(e, data.id)
                                        }
                                      ></textarea>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  direction="row"
                                  xs={1.5}
                                  justifyContent="space-around"
                                >
                                  <Grid
                                    item
                                    sx={{
                                      cursor: "pointer",
                                      color: `${secondaryColor}`,
                                    }}
                                  >
                                    <AddCircleIcon
                                      onClick={() => {
                                        const updatedData = [...versionQA];
                                        updatedData[data.id] = {
                                          ...updatedData[data.id],
                                          add: true,
                                        };

                                        setVersionQA(updatedData);
                                      }}
                                      fontSize="large"
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    sx={{ cursor: "pointer", color: "red" }}
                                  >
                                    <DeleteIcon
                                      onClick={() => removeDialogue(data.dbid)}
                                      fontSize="large"
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              {data.add ? (
                                <Grid
                                  item
                                  container
                                  direction="row"
                                  marginTop={1}
                                >
                                  <Grid
                                    item
                                    container
                                    direction="column"
                                    xs={1.8}
                                    marginLeft={1}
                                    className={
                                      classes["AudioWorkPlaceTimeline"]
                                    }
                                    height="60px"
                                    gap={0.5}
                                  >
                                    <Grid item marginBottom={0}>
                                      <textarea
                                        className={
                                          classes[
                                            "AudioWorkPlaceTimelineEditInput"
                                          ]
                                        }
                                        value={startTimeText}
                                        type="text"
                                        onChange={(e) =>
                                          setStartTimeText(e.target.value)
                                        }
                                      ></textarea>
                                    </Grid>
                                    <Grid item>
                                      <textarea
                                        className={
                                          classes[
                                            "AudioWorkPlaceTimelineEditInput"
                                          ]
                                        }
                                        value={endTimeText}
                                        type="text"
                                        onChange={(e) =>
                                          setEndTimeText(e.target.value)
                                        }
                                      ></textarea>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    container
                                    className={classes["AudioWorkPlaceBoxes"]}
                                    direction="column"
                                    gap={1}
                                    paddingLeft={2}
                                    paddingTop={1.5}
                                    xs={6}
                                    bgcolor={secondaryColor}
                                    marginRight={2}
                                  >
                                    <Grid item>
                                      <textarea
                                        className={
                                          classes["AudioWorkTextEditInput"]
                                        }
                                        placeholder="Write note description"
                                        value={desText}
                                        type="text"
                                        onChange={(e) =>
                                          setDesText(e.target.value)
                                        }
                                      ></textarea>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    container
                                    direction="row"
                                    xs={1.2}
                                    justifyContent="space-between"
                                    // alignContent="center"
                                    marginTop={2}
                                  >
                                    <Grid
                                      item
                                      onClick={() => {
                                        AddNewDialogue(data.id);
                                      }}
                                      sx={{
                                        cursor: "pointer",
                                        color: `${secondaryColor}`,
                                      }}
                                    >
                                      <SaveIcon fontSize="large" />
                                    </Grid>
                                    <Grid
                                      item
                                      sx={{
                                        cursor: "pointer",
                                        color: "red",
                                      }}
                                    >
                                      <CancelIcon
                                        onClick={() => {
                                          const updatedData = [...versionQA];
                                          updatedData[data.id] = {
                                            ...updatedData[data.id],
                                            add: false,
                                          };
                                          setVersionQA(updatedData);
                                        }}
                                        fontSize="large"
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ) : (
                                <></>
                              )}
                            </React.Fragment>
                          ))}
                        </>
                      ) : (
                        <>
                          <Grid
                            item
                            container
                            direction="row"
                            justifyContent="space-between"
                          >
                            <Grid
                              item
                              container
                              direction="column"
                              xs={2}
                              marginLeft={1.5}
                              // justifyContent="center"
                              marginTop={1.1}
                              className={classes["AudioWorkPlaceTimeline"]}
                            >
                              <Grid color="blue" item>
                                <textarea
                                  className={
                                    classes["AudioWorkPlaceTimelineEditInput"]
                                  }
                                  style={{ height: "20px" }}
                                  value={startTimeText}
                                  type="text"
                                  onChange={(e) =>
                                    setStartTimeText(e.target.value)
                                  }
                                ></textarea>
                              </Grid>
                              <Grid item color="blue">
                                <textarea
                                  className={
                                    classes["AudioWorkPlaceTimelineEditInput"]
                                  }
                                  style={{ height: "20px" }}
                                  value={endTimeText}
                                  type="text"
                                  onChange={(e) =>
                                    setEndTimeText(e.target.value)
                                  }
                                ></textarea>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              container
                              className={classes["AudioWorkPlaceBoxes"]}
                              direction="column"
                              gap={1}
                              paddingLeft={2}
                              paddingTop={1}
                              xs={5}
                              height="54px"
                            >
                              <Grid item>
                                <textarea
                                  className={classes["AudioWorkTextEditInput"]}
                                  value={desText}
                                  type="text"
                                  placeholder="Write note description"
                                  onChange={(e) => setDesText(e.target.value)}
                                ></textarea>
                              </Grid>
                            </Grid>

                            <Grid item xs={2} container direction="row">
                              <Grid
                                item
                                marginTop={1}
                                marginRight={1}
                                onClick={() => {
                                  AddNewDialogue();
                                }}
                                sx={{
                                  cursor: "pointer",
                                  color: `${secondaryColor}`,
                                }}
                              >
                                <AddCircleIcon fontSize="large" />
                              </Grid>
                              {/* <Grid
                            item
                            fontSize="40px"
                            sx={{
                              cursor: "pointer",
                              color: "red",
                            }}
                          >
                            <CancelIcon
                              // onClick={() => {
                              //   const updatedData = [...audioData];
                              //   updatedData[data.id] = {
                              //     ...updatedData[data.id],
                              //     add: false,
                              //   };
                              //   setAudioData(updatedData);
                              // }}
                              fontSize="large"
                            />
                          </Grid> */}
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  ) : (
                    <Grid
                      container
                      justifyContent="center"
                      alignContent="center"
                    >
                      <Grid item>No version selected</Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container alignItems="center" justifyContent="center">
              <Grid item container alignItems="center" justifyContent="center">
                <Grid item>
                  <button
                    onClick={() => goToPage(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={classes["AudioWorkPlaceButton"]}
                  >
                    Previous
                  </button>
                </Grid>

                <Grid item>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                </Grid>
                <Grid item>
                  <button
                    className={classes["AudioWorkPlaceButton"]}
                    onClick={() => goToPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <QAVideoSlider
            source={videoSource}
            seekTime={seekTime}
            displayAudioTime={displayAudioTime}
            seekTimeIndex={seekTimeIndex}
          />

          <Grid
            item
            container
            xs={12}
            direction="row"
            alignContent="center"
            justifyContent="flex-end"
            marginTop={2}
          >
            {/* <Grid item marginRight={2}>
              <button
                className={classes["AudioWorkPlaceButton"]}
                onClick={saveData}
              >
                Save
              </button>
            </Grid> */}

            {/* <Grid item>
              <button
                className={classes["AudioWorkPlaceButton"]}
                onClick={submitData}
              >
                Final Submission
              </button>
            </Grid> */}
            {/* <Grid item marginLeft={2}>
              {subTranscribe.length > 0 ? (
                <>
                  <CheckIcon />
                </>
              ) : (
                <></>
              )}
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QAScreen;
