import {
  Button,
  Grid,
  MenuItem,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DELETE_PROJECT, GET_PROJECTS } from "../MProjectConst";
import {
  convertToDate,
  getLanguageName,
  getTaskName,
} from "../utils/generalFunctions.js";
import DeleteConfirmationModal from "../Components/DeleteConfirmationModal.js";
// import Loader from "../Components/Loader.js";
import { ToastContainer, toast } from "react-toastify";
import classes from "../Assets/Styles/Global.module.css";
import GlobalButton from "../Components/Buttons/GlobalButton.js";
import { secondaryColor } from "../utils/constants.js";
import { Padding } from "@mui/icons-material";

const ProjectListScreen = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [deletedProjectName, setDeletedProjectName] = useState();
  const [deletedProjectId, setDeletedProjectId] = useState();

  useEffect(() => {
    setDataLoader(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(GET_PROJECTS);

        const sortedProjects = response.data.projectsArray.sort((a, b) => {
          const dateA = new Date(
            a.creationDate?.seconds * 1000 +
              a.creationDate?.nanoseconds / 1000000
          );
          const dateB = new Date(
            b.creationDate?.seconds * 1000 +
              b.creationDate?.nanoseconds / 1000000
          );

          return dateB - dateA;
        });

        setProjects(sortedProjects);
        setDataLoader(false);
      } catch (err) {
        console.log(err);
        setDataLoader(false);
      }
    };
    fetchData();
  }, []);

  const handleDeleteProjectAPI = async (id) => {
    try {
      const response = await axios.post(DELETE_PROJECT, {
        id: id,
      });
      console.log(response.data.msg);
      toast.success("Project has been deleted");
    } catch (error) {
      console.log(error);
      toast.error("An Error has occured while deleting the project");
    }
  };

  const handleRemoveProject = (id) => {
    const newItems = projects.filter((project) => project.id !== id);
    setProjects(newItems);
  };

  const toggleDeleteConfirmModal = () => {
    setShowDeleteConfirmModal(!showDeleteConfirmModal);
  };

  return (
    <Grid item container justifyContent="center">
      {/* {console.log(projects)} */}
      <ToastContainer position="top-center" theme="dark" />

      <Grid
        item
        container
        xs={10}
        marginTop={7}
        direction="row"
        marginBottom={10}
      >
        <Grid
          item
          marginBottom={5}
          xs={3}
          sx={{
            fontSize: "40px",
            fontWeight: "900",
            // borderBottom: "2px solid #333;",
          }}
          className={classes["header"]}
        >
          View Projects
        </Grid>

        {dataLoader ? (
          <Grid item xs={12} sx={{ width: 1200 }}>
            {Array.from({ length: 10 }).map((_, index) => (
              <Skeleton
                key={index}
                animation="wave"
                width="100%"
                height={100}
              />
            ))}
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Table sx={{ minWidth: 900 }}>
              <TableHead sx={{ backgroundColor: `${secondaryColor}` }}>
                <TableRow>
                  <TableCell>Project Name</TableCell>
                  <TableCell>View Original Content</TableCell>
                  <TableCell>Original Language</TableCell>
                  <TableCell>Work Pages</TableCell>
                  <TableCell>Final Languages</TableCell>
                  <TableCell>Employes</TableCell>
                  <TableCell>Creation Date</TableCell>
                  <TableCell>Actions</TableCell>
                  <TableCell>Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projects.map((project, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ fontWeight: "600" }}>
                      {project.name}
                    </TableCell>
                    <TableCell>
                      <a
                        style={{
                          textDecoration: "none",
                          color: `${secondaryColor}`,
                        }}
                        href={project.videoUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Original Content
                      </a>
                    </TableCell>
                    <TableCell>
                      {getLanguageName(project.orginalLang)}
                    </TableCell>
                    <TableCell>
                      <GlobalButton
                        onClick={() => navigate(`/workpages/${project.id}`)}
                        width="95px"
                        style={{ fontSize: "13px", padding: "10px 10px" }}
                      >
                        Work Pages
                      </GlobalButton>
                    </TableCell>
                    <TableCell width="10px">
                      <Grid
                        item
                        container
                        direction="row"
                        gap={1}
                        alignItems="center"
                        justifyContent="center"
                        // bgcolor="red"
                      >
                        {project.finalLang?.map((language, index) => (
                          <Grid
                            xs={5.5}
                            item
                            container
                            key={index}
                            alignItems="center"
                            justifyContent="center"
                            width="300px"
                            height="32px"
                            sx={{
                              // background: `${secondaryColor}`,
                              background: "lightgrey",
                              fontWeight: "400",
                              borderRadius: "5px",
                            }}
                          >
                            <Grid item>{getLanguageName(language)}</Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Select
                        size="small"
                        // value={selectEmployee}
                        color="primary"
                        defaultValue="View Employee"
                      >
                        <MenuItem value="View Employee" disabled={true}>
                          All
                        </MenuItem>
                        {project.employes?.map((employe, index) => (
                          <MenuItem key={index} value={employe.name} disabled>
                            {employe.name} : {getTaskName(employe.role)}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <Grid container width="150%">
                        {convertToDate(project.creationDate)}
                      </Grid>
                    </TableCell>

                    <TableCell>
                      <GlobalButton
                        color="primary"
                        onClick={() => navigate(`/actions/${project.id}`)}
                      >
                        Actions
                      </GlobalButton>
                    </TableCell>

                    <TableCell width="30px">
                      <Grid item container direction="row" gap={2}>
                        <Grid item>
                          <GlobalButton
                            width="68px"
                            height="32px"
                            style={{ padding: 0 }}
                            onClick={() =>
                              navigate(`/projectlist/${project.id}`)
                            }
                          >
                            Edit
                          </GlobalButton>
                        </Grid>

                        <Grid item>
                          <Button
                            size="small"
                            color="error"
                            variant="outlined"
                            onClick={() => {
                              toggleDeleteConfirmModal();
                              setDeletedProjectName(project.name);
                              setDeletedProjectId(project.id);
                              // handleDeleteProjectAPI(project.id);
                              // handleRemoveProject(project.id);
                            }}
                          >
                            Delete
                          </Button>
                        </Grid>
                        {showDeleteConfirmModal && (
                          <DeleteConfirmationModal
                            projectName={deletedProjectName}
                            projectId={deletedProjectId}
                            onClose={toggleDeleteConfirmModal}
                            handleRemoveProject={handleRemoveProject}
                            handleDeleteProjectAPI={handleDeleteProjectAPI}
                          />
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ProjectListScreen;
