export const tasksCode = [
  { name: "Transcriber", code: "transcriber" },
  { name: "Transcriber", code: "transcribe" },

  { name: "Translator", code: "translate" },
  { name: "Translator", code: "translator" },

  { name: "Voice QA Editor", code: "voicequalityassurance" },
  { name: "Face ID Editor", code: "speakerfaceidentification" },
  { name: "Lip QA Reviewer", code: "lipqualityassurance" },
  { name: "Final QA Reviewer", code: "qualityassurance" },
];

export const tasksCodeFormal = [
  { name: "Transcriber", code: "transcriber" },
  { name: "Transcribe", code: "transcribe" },

  { name: "Translate", code: "translate" },
  { name: "Translator", code: "translator" },

  { name: "Voice QA Editing", code: "voicequalityassurance" },
  { name: "Face ID Editing", code: "speakerfaceidentification" },
  { name: "Lip QA Review", code: "lipqualityassurance" },
  { name: "Final QA Review", code: "qualityassurance" },
];
