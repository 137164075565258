import React, { useContext, useState } from "react";
import { Grid } from "@mui/material";
import { Store } from "../Store";
import { Link, useNavigate } from "react-router-dom";
import classes from "../Assets/Styles/Signin.module.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { SIGN_IN_USER } from "../MProjectConst";
import { golobalFont, labelColors } from "../utils/constants";

const SigninScreen = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { state, dispatch: ctxDispatch } = useContext(Store);
  // const { userInfo } = state;

  const signIn = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(SIGN_IN_USER, {
        email,
        password,
      });
      ctxDispatch({ type: "USER_SIGNIN", payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));

      if (data.isAdmin) navigate("/adminlanding");
      else navigate("/landing");
    } catch (err) {
      toast.error("Wrong Password/Email");
    }
  };

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      marginTop={20}
      marginBottom={21.9}
    >
      <ToastContainer position="top-center" theme="dark" color="yellow" />
      <Grid
        item
        container
        justifyContent="center"
        width="40%"
        className={classes["HomeCardDetails"]}
      >
        <form onSubmit={signIn}>
          <Grid item container direction="column">
            <Grid item container color="white" justifyContent="center">
              <Grid item className={classes["header"]}>
                Login
              </Grid>
            </Grid>
            <Grid item fontSize={23} marginBottom={1.5}>
              <div className={classes["labels"]}>Email</div>
            </Grid>
            <input
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={classes["SampleInputForm"]}
            ></input>
            <Grid item fontSize={23} marginBottom={1.5} marginTop={1.5}>
              <div className={classes["labels"]}>Password</div>
            </Grid>
            <input
              type="password"
              placeholder="Enter your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={classes["SampleInputForm"]}
            ></input>
            <Grid item marginLeft={5} marginTop={1.5}>
              <Link
                to="/signup"
                style={{
                  textDecoration: "none",
                  color: `${labelColors}`,
                  fontFamily: `${golobalFont}`,
                }}
              >
                <span>You don't have an account? Sign up</span>
              </Link>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            marginTop={3.5}
            marginBottom={4}
          >
            <button type="submit" className={classes["SampleActiveButton"]}>
              Continue
            </button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default SigninScreen;
